import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { format } from 'date-fns'
import { tr } from 'date-fns/locale'
import moment from 'moment-timezone'
import { getAppointments } from '../actions/appointmentActions'
import LoadingSpinner from './LoadingSpinner'

const CustomerAppointmentList = ({ onCancel }) => {
  const dispatch = useDispatch()

  const { appointments = [], loading = false } = useSelector((state) => ({
    appointments: state.appointmentReducer?.appointments,
    loading: state.appointmentReducer?.loading
  }))

  const [currentPage, setCurrentPage] = useState(1)
  const itemsPerPage = 3

  const totalPages = Math.ceil(appointments?.length / itemsPerPage)

  useEffect(() => {
    dispatch(getAppointments())
  }, [dispatch])

  console.log(appointments)

  const [disabledButtons, setDisabledButtons] = useState({})

  useEffect(() => {
    const newDisabledButtons = {}
    appointments.forEach((appointment) => {
      const appointmentDateTime = moment(appointment.date)

      const [startTime, endTime] = appointment.timeSlot.split('-')
      const [hour, minute] = startTime.split(':')

      appointmentDateTime.set('hour', hour)
      appointmentDateTime.set('minute', minute)
      appointmentDateTime.set('second', 0)
      appointmentDateTime.set('millisecond', 0)

      const now = moment.tz('Europe/Istanbul')

      const differenceInMinutes = appointmentDateTime.diff(now, 'minutes')

      newDisabledButtons[appointment._id] = differenceInMinutes <= 60
    })
    setDisabledButtons(newDisabledButtons)
  }, [appointments])

  const handleNextPage = () =>
    setCurrentPage((prev) => Math.min(prev + 1, totalPages))
  const handlePrevPage = () => setCurrentPage((prev) => Math.max(prev - 1, 1))
  const handleFirstPage = () => setCurrentPage(1)
  const handleLastPage = () => setCurrentPage(totalPages)

  const startIndex = (currentPage - 1) * itemsPerPage
  const currentAppointments = appointments?.slice(
    startIndex,
    startIndex + itemsPerPage
  )

  return (
    <div>
      <h2 className="text-2xl font-bold mb-4 text-center text-cyan-600">
        Randevular
      </h2>
      {loading ? (
        <div className="font-medium pl-4">
          Yükleniyor... <LoadingSpinner />
        </div>
      ) : appointments.length === 0 ? (
        <p className="text-xl font-semibold text-center pb-4">
          <span className="mr-1">😔</span>Görüntülenecek Randevu Bulunmuyor..
        </p>
      ) : (
        <>
          <ul className="space-y-4 mx-4 pb-4">
            {currentAppointments.map((appointment) => (
              <li
                key={appointment._id}
                className="p-4 border rounded-2xl shadow-sm bg-gray-50"
              >
                <p className="text-lg font-semibold">
                  <span className="text-xl mr-1">📅</span>
                  {format(new Date(appointment.date), 'dd/MM/yyyy')}{' '}
                  <span className="text-xl mx-2">⁃</span>
                  <span className="text-xl mr-1">🕒</span>
                  {appointment.timeSlot}
                </p>

                <p className="text-lg font-semibold">
                  <span className="text-xl mr-1">👥</span>
                  {appointment.employee?.firstName || 'Silinmiş Ç.'}{' '}
                  {appointment.employee?.lastName}{' '}
                  {/* İsim çok uzunsa span ⁃ görünmesin, mobil görünümde uzun isimler nedeniyle Telefon Numarası alta kayınca, aradaki - spanı kaybolur. */}
                  {appointment.employee?.firstName?.length +
                    appointment.employee?.lastName?.length <
                    14 && <span className="text-xl mx-2">⁃</span>}
                  <span className="inline-block">
                    <span className="text-xl">📱</span>
                    <a
                      href="tel:05065746917"
                      className="relative text-indigo-600 after:absolute after:left-0 after:bottom-0 after:h-[2px] after:w-full after:scale-x-0 after:bg-indigo-600 after:transition-transform after:duration-300 hover:after:scale-x-100"
                    >
                      {/* {appointment.employee?.phone} */}
                      05065746917
                    </a>
                  </span>
                </p>

                <p className="text-lg font-semibold">
                  <span className="inline-block">
                    <span className="text-xl mr-1">🧔🏻</span>
                    <span>Hizmet</span>
                    <span className="text-xl mx-2">⁃</span>
                    {appointment.service?.name}
                  </span>
                </p>

                <p className="text-lg font-semibold">
                  <span className="text-xl mr-1">👀</span>Geçerlilik{' '}
                  <span className="text-xl mx-2">⁃</span>
                  {appointment.status === 'Scheduled'
                    ? '🟢 Randevu Aktif'
                    : appointment.status === 'Completed'
                    ? '✅ Tamamlandı'
                    : '❌ İptal Edildi'}
                </p>
                <button
                  onClick={() => onCancel(appointment._id)}
                  disabled={
                    disabledButtons[appointment._id] ||
                    appointment.status === 'Cancelled'
                  }
                  className={`mt-2 py-1 px-3 text-md font-semibold rounded-md  ${
                    disabledButtons[appointment._id] ||
                    appointment.status === 'Cancelled'
                      ? 'bg-gray-300 text-gray-500 cursor-not-allowed'
                      : 'bg-red-500 text-white hover:bg-red-600 transition duration-200'
                  }`}
                >
                  {appointment.status === 'Cancelled' || 'Completed'
                    ? 'Randevu İptal Et'
                    : 'Randevu İptal Et'}
                </button>
              </li>
            ))}
          </ul>

          {/* Sayfalama Kontrolleri */}
          <div className="flex justify-center space-x-4 mt-4 pb-4 md:pb-0">
            <button
              onClick={handleFirstPage}
              disabled={currentPage === 1}
              className="flex items-center justify-center px-3 h-8 text-sm font-medium text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 rounded-lg"
            >
              <svg
                className="w-6 h-6"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1.5"
                  d="m17 16-4-4 4-4m-6 8-4-4 4-4"
                />
              </svg>
              {/* İlk */}
            </button>

            <button
              onClick={handlePrevPage}
              disabled={currentPage === 1}
              className="flex items-center justify-center px-3 h-8 text-sm font-medium text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 rounded-lg"
            >
              <svg
                className="w-6 h-6"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1.5"
                  d="m14 8-4 4 4 4"
                />
              </svg>
              {/* Önceki */}
            </button>

            <span className="py-1 px-3 text-gray-500">
              {currentPage} / {totalPages}
            </span>

            <button
              onClick={handleNextPage}
              disabled={currentPage === totalPages}
              className="flex items-center justify-center px-3 h-8 text-sm font-medium text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 rounded-lg"
            >
              {/* Sonraki */}
              <svg
                className="w-6 h-6"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1.5"
                  d="m10 16 4-4-4-4"
                />
              </svg>
            </button>

            <button
              onClick={handleLastPage}
              disabled={currentPage === totalPages}
              className="flex items-center justify-center px-3 h-8 text-sm font-medium text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 rounded-lg"
            >
              {/* Son */}
              <svg
                className="w-6 h-6"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1.5"
                  d="m7 16 4-4-4-4m6 8 4-4-4-4"
                />
              </svg>
            </button>
          </div>
        </>
      )}
    </div>
  )
}

export default CustomerAppointmentList
