import React, { useEffect, useState } from 'react'
import { createSelector } from 'reselect'
import { useSelector, useDispatch } from 'react-redux'
import { format } from 'date-fns'
import { tr } from 'date-fns/locale'
import moment from 'moment-timezone'
import {
  getAppointments,
  cancelAppointment,
  deleteAppointment
} from '../actions/appointmentActions'
import LoadingSpinner from './LoadingSpinner'

const selectLoading = createSelector(
  (state) => state.appointmentReducer,
  (appointmentReducer) => appointmentReducer?.loading ?? false
)

const selectAppointment = createSelector(
  (state) => state.appointmentReducer,
  (appointmentReducer) => appointmentReducer?.appointments ?? false
)

const selectUser = createSelector(
  (state) => state.authReducer,
  (authReducer) => authReducer?.user ?? false
)

const EmployeeAppointmentList = () => {
  const dispatch = useDispatch()

  const loading = useSelector(selectLoading)

  const appointments = useSelector(selectAppointment)

  const user = useSelector(selectUser)

  const [disabledButtons, setDisabledButtons] = useState({})

  const [selectedDate, setSelectedDate] = useState('')
  const [filterActive, setFilterActive] = useState(false)
  const [filteredAppointments, setFilteredAppointments] = useState(appointments)
  const [isTodayFilter, setIsTodayFilter] = useState(false)

  const [currentPage, setCurrentPage] = useState(1)
  const itemsPerPage = 10

  const totalPages = Math.ceil(filteredAppointments?.length / itemsPerPage)

  useEffect(() => {
    if (user) {
      dispatch(getAppointments())
    }
  }, [dispatch, user])

  useEffect(() => {
    const newDisabledButtons = {}
    appointments.forEach((appointment) => {
      const appointmentDateTime = moment(appointment.date)
      appointmentDateTime.set('hour', appointment.timeSlot.split(':')[0])
      appointmentDateTime.set('minute', appointment.timeSlot.split(':')[1])
      appointmentDateTime.set('second', 0)
      appointmentDateTime.set('millisecond', 0)

      const now = moment.tz('Europe/Istanbul')
      const differenceInMinutes = appointmentDateTime.diff(now, 'minutes')

      newDisabledButtons[appointment._id] = differenceInMinutes <= 60
    })
    setDisabledButtons(newDisabledButtons)
  }, [appointments])

  useEffect(() => {
    let updatedAppointments = [...appointments]

    if (selectedDate) {
      const formattedDate = format(selectedDate, 'yyyy-MM-dd')
      updatedAppointments = updatedAppointments.filter(
        (appointment) =>
          format(new Date(appointment.date), 'yyyy-MM-dd') === formattedDate
      )
    }

    if (filterActive) {
      updatedAppointments = updatedAppointments.filter(
        (appointment) => appointment.status === 'Scheduled'
      )
    }

    if (isTodayFilter) {
      const today = format(new Date(), 'yyyy-MM-dd')
      updatedAppointments = updatedAppointments.filter(
        (appointment) =>
          format(new Date(appointment.date), 'yyyy-MM-dd') === today
      )
    }

    setFilteredAppointments(updatedAppointments)
  }, [selectedDate, filterActive, isTodayFilter, appointments])

  const handleCancel = async (appointmentId) => {
    await cancelAppointment(appointmentId)
    dispatch(getAppointments())
  }

  const handleShowActive = () => {
    setFilterActive(!filterActive)
  }

  const handleShowToday = () => {
    if (isTodayFilter) {
      setIsTodayFilter(false)
    } else {
      setIsTodayFilter(true)
      setSelectedDate('')
    }
  }

  const handleNextPage = () =>
    setCurrentPage((prev) => Math.min(prev + 1, totalPages))
  const handlePrevPage = () => setCurrentPage((prev) => Math.max(prev - 1, 1))
  const handleFirstPage = () => setCurrentPage(1)
  const handleLastPage = () => setCurrentPage(totalPages)

  const startIndex = (currentPage - 1) * itemsPerPage
  const currentAppointments = filteredAppointments?.slice(
    startIndex,
    startIndex + itemsPerPage
  )

  return (
    <div>
      <h2 className="text-2xl font-bold mb-4 text-center text-blue-600">
        Randevular
      </h2>

      <div className="flex flex-row justify-center mx-4 mb-4 space-y-4 md:space-y-0 space-x-2 md:space-x-4">
        {/* Tarih seçici */}
        <div className="w-2/5 flex flex-col md:w-1/3">
          {/* React date-picker eklentisiyle Takvim */}
          {/* <DatePicker
            selected={selectedDate}
            onChange={(date) => {
              setSelectedDate(date)
              setIsTodayFilter(false) 
            }}
            dateFormat="dd/MM/yyyy"
            className="p-2 border rounded-md mb-2 w-full"
            placeholderText="Tarih Seç"
            locale={tr}
          /> */}
          <input
            type="date"
            value={selectedDate}
            onChange={(e) => {
              setSelectedDate(e.target.value)
              setIsTodayFilter(false)
            }}
            required
            className="p-1 border rounded-md mb-2 w-full border-gray-300 bg-white shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-opacity-75 focus:border-indigo-500"
            onClick={(e) => e.target.showPicker()}
          />
          <button
            onClick={() => setSelectedDate('')}
            className="py-2 px-1 md:px-4 bg-gray-400 text-white font-bold rounded-md hover:bg-gray-500 w-full text-sm md:text-base"
          >
            Tarihi Temizle
          </button>
        </div>

        {/* Sadece Aktif Randevuları Göster butonu */}
        <button
          onClick={handleShowActive}
          className={`w-1.5/5 py-2 px-1 md:px-4 text-white font-bold rounded-md text-sm md:text-base ${
            filterActive
              ? 'bg-blue-500 hover:bg-blue-600'
              : 'bg-green-500 hover:bg-green-600'
          }`}
        >
          {filterActive ? 'Tümünü Göster' : 'Aktifleri Göster'}
        </button>

        {/* Bugünkü Randevularım butonu */}
        <button
          onClick={handleShowToday}
          className={`w-1.5/5 py-2 px-1 md:px-4 text-white font-bold rounded-md text-sm md:text-base ${
            isTodayFilter
              ? 'bg-blue-500 hover:bg-blue-600'
              : 'bg-yellow-500 hover:bg-yellow-600'
          }`}
        >
          {isTodayFilter ? 'Tümünü Göster' : 'Bugünü Göster'}
        </button>
      </div>

      {loading ? (
        <div className="font-medium">
          Yükleniyor... <LoadingSpinner />
        </div>
      ) : filteredAppointments.length === 0 ? (
        <p className="text-xl font-semibold text-center py-4">
          <span className="mr-1">🧔🏻</span>Görüntülenecek Randevu Bulunmuyor..
        </p>
      ) : (
        <>
          <ul className="space-y-4 mx-4 pb-4">
            {currentAppointments.map((appointment) => (
              <li
                key={appointment._id}
                className="p-4 border rounded-2xl shadow-sm bg-gray-50"
              >
                <p className="text-lg font-semibold">
                  <span className="text-xl mr-1">📅</span>
                  {format(new Date(appointment.date), 'dd/MM/yyyy')}{' '}
                  <span className="text-xl mx-2">⁃</span>
                  <span className="text-xl mr-1">🕒</span>
                  {appointment.timeSlot}
                </p>

                <p className="text-lg font-semibold">
                  <span className="text-xl mr-1">👥</span>
                  {appointment.customer?.firstName ||
                    appointment.firstName ||
                    'Silinmiş Müşteri'}{' '}
                  {appointment.customer?.lastName || appointment.lastName}{' '}
                  {appointment.customer?.firstName?.length +
                    appointment.customer?.lastName?.length <
                    14 && <span className="text-xl mx-2">⁃</span>}
                  <span className="inline-block">
                    <span className="text-xl">📱</span>
                    <a
                      href={`tel:${
                        appointment.customer?.phone || appointment.phone
                      }`}
                      className="relative text-indigo-600 after:absolute after:left-0 after:bottom-0 after:h-[2px] after:w-full after:scale-x-0 after:bg-indigo-600 after:transition-transform after:duration-300 hover:after:scale-x-100"
                    >
                      {appointment.customer?.phone || appointment.phone}
                    </a>
                  </span>
                </p>

                <p className="text-lg font-semibold">
                  <span className="inline-block">
                    <span className="text-xl mr-1">🧔🏻</span>
                    <span>Hizmet</span>
                    <span className="text-xl mx-2">⁃</span>
                    {appointment.service?.name}
                  </span>
                </p>

                <p className="text-lg font-semibold">
                  <span className="text-xl mr-1">👀</span>Geçerlilik{' '}
                  <span className="text-xl mx-2">⁃</span>
                  {appointment.status === 'Scheduled'
                    ? '🟢 Randevu Aktif'
                    : appointment.status === 'Completed'
                    ? '✅ Tamamlandı'
                    : '❌ İptal Edildi'}
                </p>
                <button
                  onClick={() => handleCancel(appointment._id)}
                  disabled={
                    disabledButtons[appointment._id] ||
                    appointment.status === 'Cancelled'
                  }
                  className={`mt-2 py-1 px-3 text-md font-semibold rounded-md  ${
                    disabledButtons[appointment._id] ||
                    appointment.status === 'Cancelled'
                      ? 'bg-gray-300 text-gray-500 cursor-not-allowed'
                      : 'bg-red-500 text-white hover:bg-red-600 transition duration-200'
                  }`}
                >
                  {appointment.status === 'Cancelled' || 'Completed'
                    ? 'Randevu İptal Et'
                    : 'Randevu İptal Et'}
                </button>
              </li>
            ))}
          </ul>

          {/* Sayfalama Kontrolleri */}
          <div className="flex justify-center space-x-4 mt-4 pb-4 md:pb-0">
            <button
              onClick={handleFirstPage}
              disabled={currentPage === 1}
              className="flex items-center justify-center px-3 h-8 text-sm font-medium text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 rounded-lg"
            >
              <svg
                className="w-6 h-6"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1.5"
                  d="m17 16-4-4 4-4m-6 8-4-4 4-4"
                />
              </svg>
              {/* İlk */}
            </button>

            <button
              onClick={handlePrevPage}
              disabled={currentPage === 1}
              className="flex items-center justify-center px-3 h-8 text-sm font-medium text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 rounded-lg"
            >
              <svg
                className="w-6 h-6"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1.5"
                  d="m14 8-4 4 4 4"
                />
              </svg>
              {/* Önceki */}
            </button>

            <span className="py-1 px-3 text-gray-500">
              {currentPage} / {totalPages}
            </span>

            <button
              onClick={handleNextPage}
              disabled={currentPage === totalPages}
              className="flex items-center justify-center px-3 h-8 text-sm font-medium text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 rounded-lg"
            >
              {/* Sonraki */}
              <svg
                className="w-6 h-6"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1.5"
                  d="m10 16 4-4-4-4"
                />
              </svg>
            </button>

            <button
              onClick={handleLastPage}
              disabled={currentPage === totalPages}
              className="flex items-center justify-center px-3 h-8 text-sm font-medium text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 rounded-lg"
            >
              {/* Son */}
              <svg
                className="w-6 h-6"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1.5"
                  d="m7 16 4-4-4-4m6 8 4-4-4-4"
                />
              </svg>
            </button>
          </div>
        </>
      )}
    </div>
  )
}

export default EmployeeAppointmentList
