import {
  legacy_createStore as createStore,
  applyMiddleware,
  combineReducers
} from 'redux'
import { composeWithDevTools } from '@redux-devtools/extension'
import { thunk } from 'redux-thunk'
import authReducer from '../reducers/authReducer'
import appointmentReducer from '../reducers/appointmentReducer'
import employeeReducer from '../reducers/employeeReducer'
import adminReducer from '../reducers/adminReducer'
import closedReducer from '../reducers/closedReducer'
import serviceReducer from '../reducers/serviceReducer'

const rootReducers = combineReducers({
  authReducer,
  appointmentReducer,
  employeeReducer,
  adminReducer,
  closedReducer,
  serviceReducer
})

const store = createStore(
  rootReducers,
  composeWithDevTools(applyMiddleware(thunk))
)

export default store
