import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import {
  getAppointments,
  cancelAppointment,
  deleteAppointment
} from '../../actions/Admin/adminAppointmentActions'
import { getEmployees } from '../../actions/Admin/adminEmployeeActions'
import { FaCalendarAlt } from 'react-icons/fa'
import { GiReturnArrow } from 'react-icons/gi'

const AppointmentManagement = () => {
  const dispatch = useDispatch()
  const {
    employees = [],
    appointments = [],
    loading = false
  } = useSelector((state) => state.adminReducer)

  const [selectedEmployee, setSelectedEmployee] = useState('')
  const [searchQuery, setSearchQuery] = useState('')
  const [filteredAppointments, setFilteredAppointments] = useState([])
  const [selectedDate, setSelectedDate] = useState('')
  const [filterActive, setFilterActive] = useState(false)

  const [currentPage, setCurrentPage] = useState(1)
  const itemsPerPage = 5

  const totalPages = Math.ceil(filteredAppointments?.length / itemsPerPage)

  useEffect(() => {
    dispatch(getEmployees())
  }, [dispatch])

  useEffect(() => {
    if (employees.length > 0) {
      dispatch(getAppointments(''))
    }
  }, [dispatch, employees])

  useEffect(() => {
    if (appointments) {
      let filtered = appointments

      if (selectedDate) {
        filtered = filtered.filter(
          (appointment) =>
            new Date(appointment.date).toLocaleDateString() ===
            new Date(selectedDate).toLocaleDateString()
        )
      }

      if (searchQuery.trim() !== '') {
        const terms = searchQuery
          .toLowerCase()
          .split(' ')
          .filter((term) => term)

        filtered = filtered.filter((appointment) => {
          return terms.every(
            (term) =>
              appointment.customer.firstName.toLowerCase().includes(term) ||
              appointment.customer.lastName.toLowerCase().includes(term) ||
              appointment.customer.phone.includes(term)
          )
        })
      }

      if (filterActive) {
        filtered = filtered.filter(
          (appointment) => appointment.status === 'Scheduled'
        )
      }

      filtered = filtered.sort((a, b) => new Date(b.date) - new Date(a.date))

      setFilteredAppointments(filtered)
    }
  }, [appointments, searchQuery, selectedDate, filterActive])

  const handleEmployeeSelect = (e) => {
    setSelectedEmployee(e.target.value)
    dispatch(getAppointments(e.target.value))
  }

  const handleShowActive = () => {
    setFilterActive(!filterActive)
  }

  const handleCancelAppointment = (appointmentId) => {
    dispatch(cancelAppointment(appointmentId))
  }

  const handleDeleteAppointment = (appointmentId) => {
    dispatch(deleteAppointment(appointmentId))
  }

  const getStatusText = (status) => {
    switch (status) {
      case 'Cancelled':
        return '❌'
      case 'Scheduled':
        return '🟢'
      case 'Completed':
        return '✅'
      default:
        return status
    }
  }

  const formatEmployeeName = (firstName, lastName) => {
    if (!firstName || !lastName) {
      return 'Silinmiş Ç.'
    }
    return `${firstName.charAt(0)}. ${lastName.charAt(0)}.`
  }

  const handleNextPage = () =>
    setCurrentPage((prev) => Math.min(prev + 1, totalPages))
  const handlePrevPage = () => setCurrentPage((prev) => Math.max(prev - 1, 1))
  const handleFirstPage = () => setCurrentPage(1)
  const handleLastPage = () => setCurrentPage(totalPages)

  const startIndex = (currentPage - 1) * itemsPerPage
  const currentAppointments = filteredAppointments?.slice(
    startIndex,
    startIndex + itemsPerPage
  )

  return (
    <div className="max-w-6xl mx-auto p-6 bg-white rounded-lg shadow-md">
      <div className="flex items-center mb-4">
        <Link to="/admin-dashboard" className="">
          <GiReturnArrow className="text-3xl" />
        </Link>

        <h2 className="text-2xl font-bold flex-grow flex items-center justify-center">
          Randevu Yönetimi <FaCalendarAlt className="ml-2" />
        </h2>
      </div>

      {/* {error && <p className="text-center text-red-500">{error}</p>} */}

      {/* Çalışan Seçimi */}
      <select
        value={selectedEmployee}
        onChange={handleEmployeeSelect}
        className="w-full p-2 mb-4 border rounded-md"
      >
        <option value="">Tüm Çalışanlar</option>
        {employees.map((employee) => (
          <option key={employee._id} value={employee._id}>
            {employee.firstName} {employee.lastName}
          </option>
        ))}
      </select>

      {/* Tarih Seçimi */}
      <input
        type="date"
        value={selectedDate}
        onChange={(e) => setSelectedDate(e.target.value)}
        onFocus={(e) => e.target.showPicker()}
        className="w-full p-2 mb-4 border rounded-md"
      />

      {/* Arama Kutusu */}
      <input
        type="text"
        placeholder="Müşteri Ad, Soyad veya Telefon ile ara"
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        className="w-full p-2 mb-4 border rounded-md"
      />

      {/* Sadece Aktif Randevuları Göster butonu */}
      <button
        onClick={handleShowActive}
        className={`w-1.5/5 mb-4 py-2 px-4 text-white font-bold rounded-md text-sm md:text-base ${
          filterActive
            ? 'bg-blue-500 hover:bg-blue-600'
            : 'bg-green-500 hover:bg-green-600'
        }`}
      >
        {filterActive ? 'Tümünü Göster' : 'Sadece Aktifleri Göster'}
      </button>

      {loading ? (
        <p className="text-center text-gray-500 font-semibold">
          Randevular Yükleniyor...
        </p>
      ) : filteredAppointments.length > 0 ? (
        /* Randevu Tablosu */
        <div className="overflow-x-auto">
          <table className="min-w-full bg-white border border-gray-200">
            <thead>
              <tr className="text-left">
                <th className="py-2 pl-4 pr-2 md:px-4 border-b">Tarih</th>
                <th className="py-2 px-2 md:px-4 border-b">Saat</th>
                <th className="py-2 px-2 md:px-4 border-b">Müşteri</th>
                <th className="py-2 px-2 md:px-4 border-b">Telefon</th>
                <th className="py-2 border-b text-center">Çalışan</th>
                <th className="py-2 border-b text-center">Hizmet</th>
                <th className="py-2 px-2 border-b text-center">Durum</th>
                <th className="py-2 px-3 md:px-4 border-b">İşlemler</th>
              </tr>
            </thead>
            <tbody>
              {currentAppointments.map((appointment) => (
                <tr key={appointment._id} className="hover:bg-gray-100">
                  <td className="py-2 pl-4 pr-2 md:px-4 border-b">
                    {new Date(appointment.date).toLocaleDateString()}
                  </td>

                  <td className="py-2 px-2 md:px-4 border-b">
                    {appointment.timeSlot}
                  </td>

                  <td className="py-2 px-2 md:px-4 border-b">
                    {appointment.customer?.firstName ||
                      appointment.firstName ||
                      'Silinmiş Müşteri'}{' '}
                    {appointment.customer?.lastName || appointment.lastName}
                  </td>

                  <td className="py-2 px-2 md:px-4 border-b">
                    {appointment.customer?.phone || appointment.phone}
                  </td>

                  <td className="py-2 px-2 md:px-4 border-b text-center">
                    {formatEmployeeName(
                      appointment.employee?.firstName,
                      appointment.employee?.lastName
                    )}
                  </td>

                  <td className="py-2 px-2 md:px-2 border-b text-center">
                    {appointment.service?.name}
                  </td>

                  <td className="py-2 px-2 md:px-4 border-b text-center">
                    {getStatusText(appointment.status)}
                  </td>

                  <td className="py-2 px-2 md:px-4 border-b flex flex-col md:flex-row">
                    <button
                      onClick={() => handleCancelAppointment(appointment._id)}
                      disabled={appointment.status === 'Cancelled'}
                      className={`${
                        appointment.status === 'Cancelled' ||
                        appointment.status === 'Completed'
                          ? 'bg-gray-500 text-white cursor-not-allowed'
                          : 'bg-yellow-500 text-white hover:bg-yellow-600 cursor-pointer'
                      } px-2 md:px-4 py-2 rounded-md mb-2 md:mb-0 md:mr-2`}
                    >
                      İptal Et
                    </button>

                    <button
                      onClick={() => handleDeleteAppointment(appointment._id)}
                      className="bg-red-500 text-white px-2 md:px-4 py-2 rounded-md hover:bg-red-600"
                    >
                      Sil
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          {/* Sayfalama Kontrolleri */}
          <div className="flex justify-center space-x-4 mt-4 pb-4 md:pb-0">
            <button
              onClick={handleFirstPage}
              disabled={currentPage === 1}
              className="flex items-center justify-center px-3 h-8 text-sm font-medium text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 rounded-lg"
            >
              <svg
                className="w-6 h-6"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1.5"
                  d="m17 16-4-4 4-4m-6 8-4-4 4-4"
                />
              </svg>
              {/* İlk */}
            </button>

            <button
              onClick={handlePrevPage}
              disabled={currentPage === 1}
              className="flex items-center justify-center px-3 h-8 text-sm font-medium text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 rounded-lg"
            >
              <svg
                className="w-6 h-6"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1.5"
                  d="m14 8-4 4 4 4"
                />
              </svg>
              {/* Önceki */}
            </button>

            <span className="py-1 px-3 text-gray-500">
              {currentPage} / {totalPages}
            </span>

            <button
              onClick={handleNextPage}
              disabled={currentPage === totalPages}
              className="flex items-center justify-center px-3 h-8 text-sm font-medium text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 rounded-lg"
            >
              {/* Sonraki */}
              <svg
                className="w-6 h-6"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1.5"
                  d="m10 16 4-4-4-4"
                />
              </svg>
            </button>

            <button
              onClick={handleLastPage}
              disabled={currentPage === totalPages}
              className="flex items-center justify-center px-3 h-8 text-sm font-medium text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 rounded-lg"
            >
              {/* Son */}
              <svg
                className="w-6 h-6"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1.5"
                  d="m7 16 4-4-4-4m6 8 4-4-4-4"
                />
              </svg>
            </button>
          </div>
        </div>
      ) : (
        <p className="text-center text-gray-500 font-semibold">
          Hiç randevu bulunamadı.
        </p>
      )}
    </div>
  )
}

export default AppointmentManagement
